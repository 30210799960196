(function() {

  'use strict';
  /* global $, angular */

  angular.module("Milestone").controller("faqCtrl", ["$scope",
    function($scope) {

      //FAQ
      $scope.faqs = [{
      	'title': 'Where does MileStone have communities? ',
        'body': 'We currently have communities in <a href="https://www.mymilestone.com/north-austin" target="_blank">North Austin</a>, <a href="https://www.mymilestone.com/downtown-south-austin" target="_blank">South Austin</a>, <a href="https://www.mymilestone.com/buda-and-kyle" target="_blank">Buda & Kyle</a>, <a href="https://www.mymilestone.com/dripping-springs" target="_blank">Dripping Springs</a>, <a href="https://www.mymilestone.com/round-rock" target="_blank">Round Rock</a>, <a href="https://www.mymilestone.com/leander-and-liberty-hill" target="_blank">Leander & Liberty Hill</a> and <a href="https://www.mymilestone.com/west-austin" target="_blank">West Austin</a>. We also have a page set aside for communities that are <a href="https://www.mymilestone.com/comingsoon">coming soon</a>!'
      },{
      	'title': 'What is MileStone Community Builders?',
        'body': 'MileStone is a central Texas builder that builds homes for Texans built by Texans. We love where we live and strive to ensure our communities work with the areas in which they are built and help to improve the overall community. Learn about <a href="https://www.mymilestone.com/why-milestone/more-about-milestone" target="_blank">our vision</a>, <a href="https://www.mymilestone.com/why-milestone/the-team" target="_blank">our team</a>, <a href="https://www.mymilestone.com/why-milestone/awards" target="_blank">our awards</a> and <a href="https://www.mymilestone.com/why-milestone/testimonials" target="_blank">our home owner testimonials.</a><br><br>'
      },{
      	'title': 'Do you have a resource for the home buying process?',
      	'body': 'We do! Check out our <a href="https://www.mymilestone.com/buying-milestone/buying-process" target="_blank">Buying Process page</a> which also includes information about our preferred lenders.'
      },{
      	'title': 'Am I able to customize my home?',
      	'body': 'Yes! Within 2 weeks of completing the contract of your new home you can make an appointment with Jessica Nixon Interior Design. For more information, visit our <a href="https://www.mymilestone.com/buying-milestone/designing-your-milestone-home" target="_blank">Designing Your MileStone home page</a>.'
      },{
      	'title': 'Is there a way to get more information on each community? ',
      	'body': 'Yes! Each community has its own page with information about the community’s location, available floorplans and lots, amenities and more!'
        +'<h3>North Austin Communities</h3>'
        +'<p><a href="https://www.mymilestone.com/north-austin/foxfield" target="_blank">Foxfield</a> <br>'
        +  '<a href="https://www.mymilestone.com/north-austin/koenig-townhomes" target="_blank">Koenig Townhomes</a> <br>'
        +  '<a href="https://www.mymilestone.com/north-austin/loyola" target="_blank">Loyola</a> </p>'
        +'<h3>South Austin Communities</h3>'
        +'<p><a href="https://www.mymilestone.com/downtown-south-austin/the-hills-of-bear-creek" target="_blank">The Hills of Bear Creek</a> <br>'
        +  '<a href="https://www.mymilestone.com/downtown-south-austin/messinger-village" target="_blank">Messinger Village</a> <br>'
        +  '<a href="https://www.mymilestone.com/downtown-south-austin/vistas-of-austin" target="_blank">Vistas of Austin</a> </p>'
        +'<h3>Buda & Kyle Communities</h3>'
        +'<p><a href="https://www.mymilestone.com/buda-and-kyle/crosswinds" target="_blank">Crosswinds</a> <br>'
        +  '<a href="https://www.mymilestone.com/buda-and-kyle/porter-country" target="_blank">Porter Country</a> <br>'
        +  '<a href="https://www.mymilestone.com/buda-and-kyle/sage-hollow" target="_blank">Sage Hollow</a> </p>'
        +'<h3>Dripping Springs Communities</h3>'
        +'<p><a href="https://www.mymilestone.com/dripping-springs/skyridge" target="_blank">Skyridge</a> </p>'
        +'<h3>Round Rock Communities</h3>'
        +'<p><a href="https://www.mymilestone.com/round-rock/sauls-ranch" target="_blank">Sauls Ranch</a> </p>'
        +'<h3>Leander & Liberty Hill Communities</h3>'
        +'<p><a href="https://www.mymilestone.com/leander-and-liberty-hill/bonnet" target="_blank">Bonnet</a> <br>'
        +  '<a href="https://www.mymilestone.com/leander-and-liberty-hill/larkspur" target="_blank">Larkspur</a> </p>'
        +'<h3>West Austin Communities</h3>'
        +'<p><a href="https://www.mymilestone.com/west-austin/milky-way-at-river-place" target="_blank">Milky Way at River Place</a> </p>'
      },{
      	'title': 'Do you have any special discounts for new home buyers?',
      	'body': 'Our Hometown Heroes is a program we created to celebrate local residents who do good for their communities. Their good deeds can help then earn up to $2000 off of their new home purchase. For more information, visit our Hometown Heroes page <a href="https://www.mymilestone.com/why-milestone/hometown-heroes" target="_blank">here</a>.'
      },{
      	'title': 'How can I work for MileStone?',
      	'body': 'Be sure to check on our <a href="https://www.mymilestone.com/careers" target="_blank">Careers page</a> for any current job listings'
      }];

    }
  ]);

})();
